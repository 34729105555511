// Ant design then Tailwind and
// custom, imported last so we can overwrite CSS set elsewhere.
// import "antd/dist/antd.css"; // Untouched antd (not used as global styles)
// Preflight is a custom CSS reset that should go first
import "../styles/preflight.css";
// Ant design (fyi we want to get rid of this!) next before tailwind
import "../styles/antd.less"; // TODO: remove ANTD
// The main Tailwind CSS file
import "../styles/index.css";

import { ActiveOrganizationProvider } from "@/context/ActiveOrganizationProvider";
import { TabContextProvider } from "@/context/TabContextProvider";
import { TourtipProvider } from "@/context/TourtipContext";
import DayjsConfig from "@components/llama/DayjsConfig";
import { HumanloopToaster } from "@library/Toast";
import { IconContext } from "@phosphor-icons/react";
import { ProvideAuth } from "lib/use-auth";
import type { AppProps } from "next/app";
import { NuqsAdapter } from "nuqs/adapters/next/pages";
import { ApiService } from "services/api.service";
import { SWRConfig } from "swr";
import { AppErrorBoundary } from "../components/error/AppErrorBoundary";
import { ConfirmProvider } from "../context/useConfirm";
import { ReactScan } from "@components/dev/ReactScan";
import { AnalyticsProvider } from "@components/analytics/AnalyticsProvider";

function Humanloop({ Component, pageProps }: AppProps) {
  // @ts-ignore - getLayout is a custom property on the page component, following the pattern here: https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts#per-page-layouts
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <SWRConfig
      value={{
        fetcher: ApiService.getWithToken,
        // Our app has many places where re-renders happen on mouse moves and table resizes (questionable in its own right).
        // A quick fix here is to change the default dedupe interval in SWR to 30 seconds.
        // TODO: a better fix would be tackle the unnecessary rerenders, but this is trickier to diagnose.
        dedupingInterval: 30000,
      }}
    >
      <ReactScan />
      {/* https://nuqs.47ng.com/docs/adapters#nextjs-pages-router */}
      <NuqsAdapter>
        <IconContext.Provider value={{ size: 16, weight: "bold" }}>
          <AnalyticsProvider>
            <ProvideAuth>
              {/* Generic Error boundary reporting to capture full app crashes. 
            I can't figure out a way to prefill user details if we have them but that would be preferable. */}
              <AppErrorBoundary>
                <ActiveOrganizationProvider>
                  <ConfirmProvider>
                    <TourtipProvider>
                      <DayjsConfig />

                      <TabContextProvider>{getLayout(<Component {...pageProps} />)}</TabContextProvider>
                      <HumanloopToaster />
                    </TourtipProvider>
                  </ConfirmProvider>
                </ActiveOrganizationProvider>
              </AppErrorBoundary>
            </ProvideAuth>
          </AnalyticsProvider>
        </IconContext.Provider>
      </NuqsAdapter>
    </SWRConfig>
  );
}
export default Humanloop;
