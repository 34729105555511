import React, { ForwardedRef, forwardRef } from "react";
import { classNames } from "./utils/classNames";
import { UnstyledButtonBase, UnstyledButtonBaseProps } from "./Button";
import { Icon as PhosphorIcon } from "@phosphor-icons/react";
import { ConditionalTooltip, TooltipProps } from "./Tooltip";

// Refers to the outer button size
export type IconButtonSize = 12 | 16 | 20 | 24 | 32 | 40 | 48;

export interface IconButtonProps extends UnstyledButtonBaseProps {
  // Regular button props https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
  active?: boolean;
  isWait?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  value?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  tooltip?: React.ReactNode;
  tooltipProps?: Omit<TooltipProps, "content" | "children">;

  // Our custom props
  baseStyle?: boolean;
  styling?: "solid" | "outline" | "ghost";
  Icon: ((props: React.ComponentProps<"svg">) => JSX.Element) | PhosphorIcon;

  size?: IconButtonSize; // Outer size
  iconSize?: IconButtonSize; // Icon size override (using the same mapping as overall size)
  className?: string;
  iconClassName?: string;
  style?: React.CSSProperties;
  shade?: "gray" | "black" | "blue";
  elevated?: boolean;
}

const IconButton = (props: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const {
    type = "button",
    size = "24",
    iconSize = null,
    shade = "black",
    styling = "solid",
    Icon,
    active,
    baseStyle,
    iconClassName,
    tooltip,
    tooltipProps,
    elevated,
    ...rest
  } = props;

  const classes = {
    base: "inline-flex shrink-0 justify-center items-center relative select-none rounded-ms",
    size: {
      12: "w-12 h-12",
      16: "w-16 h-16",
      20: "w-20 h-20",
      24: "w-24 h-24",
      32: "w-32 h-32",
      40: "w-40 h-40",
      48: "w-48 h-48",
    },
    // These sizes don't match the equivalent Button icons (they're bigger)
    icon: {
      12: "w-8 h-8",
      16: "w-8 h-8",
      20: "w-12 h-12",
      24: "w-14 h-14",
      32: "w-[16px] h-[16px]",
      40: "w-20 h-20",
      48: "w-24 h-24",
    },
    shade: {
      ["black"]: {
        base: "focus:outline-none focus:ring-1  focus:ring-oldgray-200",
        normal: "text-black hover:bg-oldgray-100 active:bg-oldgray-200",
        disabled: "text-oldgray-400 hover:bg-oldgray-100",
        active: "text-black bg-oldgray-200 hover:bg-oldgray-200 active:bg-oldgray-200",
      },
      ["gray"]: {
        base: "focus:outline-none focus:ring-1 focus:ring-oldgray-300",
        normal: "text-oldgray-600 hover:bg-oldgray-200 active:bg-oldgray-300",
        disabled: "text-oldgray-400 hover:bg-oldgray-100",
        active: "text-oldgray-800 bg-oldgray-200 hover:bg-oldgray-200 active:bg-oldgray-200",
      },
      ["blue"]: {
        base: "focus:outline-none focus:ring-1 focus:ring-oldgray-300",
        normal: "text-oldblue-600 hover:bg-oldgray-200 active:bg-oldgray-300",
        disabled: "text-oldblue-400 hover:bg-oldgray-100",
        active: "text-oldblue-800 bg-oldgray-200 hover:bg-oldgray-200 active:bg-oldgray-200",
      },
    },
    // Default is "solid", outline is generally different.
    // TODO: This component has not kept up with Figma.
    styling: {
      solid: "border-none",
      outline: "border border-oldgray-400 hover:border-oldgray-400 active:border-oldgray-500",
      ghost: "border-none",
    },
    disabled: props.isWait ? "cursor-wait" : "cursor-not-allowed",
    elevated: "bg-white shadow-button-1",
  };

  const shadeStyling = classes.shade[shade];
  return (
    <ConditionalTooltip condition={tooltip !== undefined && tooltip !== null} content={tooltip} {...tooltipProps}>
      <UnstyledButtonBase
        //  Spread props so this works with RadixUI's asChild.
        {...rest}
        ref={ref}
        type={type}
        onClick={props.onClick}
        className={classNames(
          props.className,
          classes.base,
          classes.size[size],
          shadeStyling.base,
          classes.styling[props.styling || "solid"],
          !baseStyle && props.disabled && classes.disabled,
          !baseStyle && (props.disabled ? shadeStyling.disabled : active ? shadeStyling.active : shadeStyling.normal),
          elevated && classes.elevated,
        )}
        disabled={props.disabled}
        style={props.style}
      >
        <props.Icon
          className={classNames(props.iconSize ? classes.icon[props.iconSize] : classes.icon[size], iconClassName)}
        />
      </UnstyledButtonBase>
    </ConditionalTooltip>
  );
};
export default forwardRef<HTMLButtonElement, IconButtonProps>(IconButton);
