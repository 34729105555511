import { CheckCircleIcon, ExclamationCircleIcon, ExclamationIcon, XIcon } from "@heroicons/react/outline";
import IconButton from "@library/IconButton";
import { InsightIcon } from "@library/Icons";
import { classNames } from "lib/utils";
import React from "react";
import toast, { Toast as ToastInterface, ToastOptions, Toaster } from "react-hot-toast";

export const enum ToastVariant {
  Info = "Info",
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
}

interface ToastProps {
  toast: ToastInterface;
  title: React.ReactNode;
  description?: React.ReactNode;
  variant: ToastVariant;
  onClick?: () => void;
  href?: string;
  target?: string;
}

/** Styling for a Humanloop toast. Intended for use by `hlToast()`. */
export const Toast = ({ toast: t, title, description, variant, onClick }: ToastProps) => {
  const variants = {
    [ToastVariant.Info]: { classes: "text-oldblue-600", icon: InsightIcon },
    [ToastVariant.Success]: { classes: "text-oldgreen-600", icon: CheckCircleIcon },
    [ToastVariant.Warning]: { classes: "text-oldyellow-600", icon: ExclamationIcon },
    [ToastVariant.Error]: { classes: "text-oldred-600", icon: ExclamationCircleIcon },
  };
  const Icon = variants[variant].icon;

  return (
    <div
      className={classNames(
        "my-2 flex",
        description ? "items-start" : "items-center",
        variants[variant].classes,
        onClick && "cursor-pointer",
      )}
      onClick={onClick}
    >
      <div className={"mr-16 h-24 w-24 flex-shrink-0 p-2"}>
        <Icon />
      </div>
      <div className="mr-24 flex flex-grow flex-col">
        <span className="text-base font-bold text-oldgray-800">{title}</span>
        {description && (
          // Not sure why this is expanding beyond the parent container but that's why we set a max-width
          <span className="mt-8 max-w-[352px] break-words text-12-16 text-oldgray-700">
            {/* If this a mistaken JSON object (but not a ReactNode JSX), stringify it, defensively */}
            {React.isValidElement(description) || typeof description === "string"
              ? description
              : JSON.stringify(description)}
          </span>
        )}
      </div>
      <IconButton
        className="shrink-0"
        Icon={XIcon}
        size={24}
        shade={"black"}
        onClick={(event) => {
          event.stopPropagation();
          toast.dismiss(t.id);
        }}
      />
    </div>
  );
};

/** Modified react-hot-toast's `Toaster` for app-wide configuration. */
export const HumanloopToaster = () => {
  return (
    <Toaster
      position="bottom-right"
      containerStyle={{ bottom: "100px" }}
      toastOptions={{ className: "", style: { maxWidth: 480 } }}
    />
  );
};

/**
 * Modified react-hot-toast's `toast()` that uses the styled library's Toast component.
 * @param toastProps Props for library Toast component
 *
 * @example
 * import { hlToast, ToastVariant } from "components/library/Toast";
 *
 * hlToast({ title: "Hello world", variant: ToastVariant.Info });
 */
export const hlToast = (toastProps: Omit<ToastProps, "toast">, options?: ToastOptions) => {
  return toast((t) => <Toast toast={t} {...toastProps} />, options);
};

export const hlToastApiError = ({
  error,
  titleFallback,
  descriptionFallback,
}: {
  error: any;
  titleFallback: string;
  descriptionFallback?: string;
}) => {
  hlToast({
    title: error.response?.data?.detail?.msg || titleFallback,
    description:
      error.response?.data?.detail?.description ||
      error.response?.data?.detail ||
      descriptionFallback ||
      `Contact us for more details.`,
    variant: ToastVariant.Error,
  });
};
