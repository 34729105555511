import * as Sentry from "@sentry/nextjs";
import { AxiosResponse } from "axios";
import Cohere from "cohere-js";
import Cookies from "js-cookie";
import { analytics } from "@/lib/analytics/analytics.client";
import { useRouter } from "next/router";
import React, { createContext, useCallback, useContext, useEffect } from "react";

import { clearActiveOrganizationSessionStorage } from "@/context/ActiveOrganizationProvider";
import {
  LoginForAccessTokenResponse,
  RecoverUserResponse,
  loginAsToken,
  loginForAccessToken,
  recoverUser,
} from "services/auth.service";
import { CurrentUser, VerifyUserRequest, VerifyUserResponse, useCurrentUser, verifyUser } from "services/users.service";
import { KeyedMutator } from "swr";
import {
  HUMANLOOP_API_URI_COOKIE_NAME,
  HUMANLOOP_APP_DOMAIN_COOKIE_NAME,
  HUMANLOOP_AUTH_COOKIE_NAME,
  LOGIN_URL,
  SIGNUP_URL,
  SIGNUP_WELCOME_URL,
} from "./constants";
import { ProductFeatureFlags } from "./featureflags";
import useLocalStorage, { getLocalStorage } from "./use-local-storage";
import { getActiveOrganizationMembership } from "./user";

export const AUTH_STORAGE = "Authorization";
const TOKEN_EXPIRY_DAYS = 7;
const AUTH_EXPIRY = 1000 * 60 * 60 * 24 * TOKEN_EXPIRY_DAYS;
const LOGGING = false;
// .humanloop.com allows the cookie to work on other .humanloop.com subdomains
// undefined allows the cookie to work on localhost
const COOKIE_DOMAIN_AVAILABILITY = process.env.NODE_ENV === "production" ? ".humanloop.com" : undefined;

interface AuthContextInterface {
  login: (username: string, password: string) => Promise<AxiosResponse<LoginForAccessTokenResponse>>;
  access: (username: string) => Promise<AxiosResponse<LoginForAccessTokenResponse>>;
  logout: (redirect?: string) => void;
  forgot: (username: string) => Promise<any>;
  reset: (password: string, token: string) => Promise<any>;
  verifyAndLogin: (request: VerifyUserRequest) => Promise<any>;
  user: CurrentUser | undefined;
  authToken: string | null;
  loading: boolean;
  mutate: KeyedMutator<CurrentUser>;
  hasAccess: (feature: ProductFeatureFlags) => boolean | undefined;
  setAuthToken: (token: string) => void;
}

const AuthContext = createContext<AuthContextInterface | null>(null);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: React.PropsWithChildren<unknown>): JSX.Element {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = (): AuthContextInterface => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthContext provider");
  }
  return context;
};

// As above but will redirect if auth has loaded and you're not logged in.
export function useRequireAuth(redirectUrl = LOGIN_URL): AuthContextInterface {
  const auth = useAuth();
  const router = useRouter();

  // If auth.user is false that means we're not
  // logged in and should redirect.
  useEffect(() => {
    if (!auth.user && !auth.loading) {
      // Set the current url path to a query param called 'next'
      // so we can redirect back after logging in, and bearing in mind
      //  that the current path can itself contain query params.
      if (!router.asPath.startsWith(redirectUrl)) {
        router.replace(`${redirectUrl}?next=${encodeURIComponent(router.asPath)}`);
      }
    }
  }, [auth, router, redirectUrl]);

  // Redirects if the signup process hasn't been completed.
  useEffect(() => {
    if (auth.user && !router.asPath.includes(SIGNUP_URL)) {
      // No org -> go and create one
      if (auth.user.organizations.length === 0) {
        router.push(SIGNUP_WELCOME_URL);
      }
    }
  }, [auth.user, router]);

  return auth;
}

/**
 * Set the analytics context for the current user.
 * This is used to set the user ID for Segment, Sentry, Cohere, FullStory etc.
 */
const setAnalyticsContext = (user: CurrentUser | null, organizationId?: string) => {
  LOGGING && console.log("Setting analytics context", user);
  // Send the user ID to Segment and other analytics tools.
  if (user && !analytics.isTrackingDisabled()) {
    // See https://segment.com/docs/connections/spec/identify/
    analytics.identify(
      user.id,
      {
        email: user.email_address,
        name: user.full_name,
        organization_id: organizationId,
      },
      organizationId,
    );
    // It should be the case that Sentry, Cohere, FullStory etc. all get the id information from Segment.
    // However, in practice, I'm not convinced Sentry is getting it (potentially Sentry being intialised first), so...
    Sentry.setUser({
      id: user.id,
      email: user.email_address,
      username: user.full_name ?? undefined,
    });
    // Similarly, Segment *should* send the identifier to Cohere, but only if
    // Segment is initialized beforehand... and it appears that it doesn't, so for now we have cohere
    // using the segment integration but we still identify directly to them too.
    // see https://docs.cohere.io/external-integrations/segment
    Cohere.identify(user.id, {
      displayName: user.full_name || user.email_address,
      email: user.email_address,
    });
  } else {
    analytics.reset();
    Sentry.getCurrentScope().setUser(null);
  }
};

interface AuthStorage {
  token: string;
  timestamp: number;
}

/**
 * Provider hook that creates auth object and handles state.
 *
 *  We don't use internal state, we just use the cached result from SWR.
 *
 */
function useProvideAuth(): AuthContextInterface {
  const [authStorage, setAuthStorage, clearAuthStorage] = useLocalStorage<AuthStorage | null>(AUTH_STORAGE, null);

  /** Set the auth token in local storage and as a cookie */
  const setAuthToken = useCallback(
    (token: string) => {
      // We set the token into local storage as the main way we use this for the app
      setAuthStorage({ token, timestamp: Date.now() });
      // We've now also setting it as a cookie (in parallel!) so
      // that we can access the token on other .humanloop.com domains
      // TODO: Ultimately, switch to just using cookies. Which could be done happily
      // after TOKEN_EXPIRY_DAYS.
      Cookies.set(HUMANLOOP_AUTH_COOKIE_NAME, token, {
        domain: COOKIE_DOMAIN_AVAILABILITY,
        // Note that we're not setting the cookie to be HttpOnly, as we need to access it from JS
        // on other .humanloop.com domains.
        expires: TOKEN_EXPIRY_DAYS,
        // Secure: Ensures the cookie is only sent over HTTPS.
        secure: true,
        // SameSite: Use "Strict" since we don't need cross-site cookie access
        sameSite: "strict",
      });
      // This is to set the domain that the user last logged in from
      // (most likely app.humanloop.com but could also be eu. or [company].humanloop.com)
      // We'll use it to direct them from the home page.
      if (process.env.NEXT_PUBLIC_VERCEL_URL) {
        Cookies.set(HUMANLOOP_APP_DOMAIN_COOKIE_NAME, process.env.NEXT_PUBLIC_VERCEL_URL, {
          domain: COOKIE_DOMAIN_AVAILABILITY,
          expires: TOKEN_EXPIRY_DAYS,
          secure: true,
          sameSite: "strict",
        });
      }
      // This is to set the API URI that the user last logged in from
      // (most likely api.humanloop.com but could also be eu. or api[company].humanloop.com)
      // We'll use it to login to the API
      if (process.env.NEXT_PUBLIC_API_URI) {
        Cookies.set(HUMANLOOP_API_URI_COOKIE_NAME, process.env.NEXT_PUBLIC_API_URI, {
          domain: COOKIE_DOMAIN_AVAILABILITY,
          expires: TOKEN_EXPIRY_DAYS,
          secure: true,
          sameSite: "strict",
        });
      }
    },
    [setAuthStorage],
  );

  useEffect(() => {
    if (authStorage && authStorage.timestamp < Date.now() - AUTH_EXPIRY) {
      console.log("Clearing auth storage due to expiry.");
      clearAuthStorage();
      // FYI cookies will be cleared automatically by the browser when the expiry date is reached.
    }
  }, [authStorage, setAuthStorage, clearAuthStorage]);

  const { user, mutate, loading, error } = useCurrentUser({
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (user !== undefined && !analytics.isTrackingDisabled()) {
      setAnalyticsContext(user, getActiveOrganizationMembership(user)?.organization.id);
    }
  }, [user]);

  /**
   * Login a user.
   *
   * Note that this function does not throw an error if the request fails.
   * Instead, the caught `error.response` is returned.
   */
  const login = async (username: string, password: string): Promise<AxiosResponse<LoginForAccessTokenResponse>> => {
    const response = await loginForAccessToken(username, password);
    const { data } = response;
    setAuthToken(data.access_token);
    // Clearing the active org on login
    clearActiveOrganizationSessionStorage();
    // Broadcast a revalidation message globally to all SWRs with this key ('/api/me'), and
    // locally mutate it with the user we've just received.
    mutate();
    analytics.enableTracking();
    return response;
  };

  const access = async (username: string): Promise<AxiosResponse<LoginForAccessTokenResponse>> => {
    // Only usable by superadmins

    const response = await loginAsToken(username);
    const { data } = response;
    setAuthToken(data.access_token);
    // Clearing the active org on login
    clearActiveOrganizationSessionStorage();
    // Broadcast a revalidation message globally to all SWRs with this key ('/api/me'), and
    // locally mutate it with the user we've just received.
    mutate();
    // Setting the analytics context to null so we don't track the user in this case
    analytics.disableTracking();
    setAnalyticsContext(null);
    return response;
  };

  // TODO: Fix the try-catch and actually throw errors here.
  //   This has been changed in our backend so that it should not ever error
  //   (even if the user does not exist)
  const forgot = async (email: string): Promise<AxiosResponse<RecoverUserResponse>> => {
    try {
      const response = await recoverUser(email);
      return response;
    } catch (err: any) {
      return err.response;
    }
  };

  /**
   * Reset a user's password.
   *
   * @param password - New password
   * @param token - The token sent to the user's email address.
   * @returns
   */
  const reset = async (password: string, token: string): Promise<AxiosResponse<VerifyUserResponse>> => {
    try {
      const response = await verifyUser({ token, password });
      const { data } = response;
      setAuthToken(data.access_token);
      mutate();
      return response;
    } catch (err: any) {
      return err.response;
    }
  };

  const verifyAndLogin = useCallback(
    async (request: VerifyUserRequest) => {
      const response = await verifyUser(request);
      const { data } = response;
      setAuthToken(data.access_token);
      mutate();

      // Clearing the active org on login
      clearActiveOrganizationSessionStorage();
      analytics.enableTracking();

      return response.data;
    },
    [mutate, setAuthToken],
  );

  const logout = (redirect: string = "/") => {
    console.log("Attempting to remove auth storage");
    clearAuthStorage();
    Cookies.remove(HUMANLOOP_AUTH_COOKIE_NAME, {
      // Note that we need to specify the same domain that was used when setting the cookie to ensure it's properly removed.
      domain: COOKIE_DOMAIN_AVAILABILITY,
    });
    // We clear these because even though they probably stay on the same domain
    // (once gusto.humanloop.com, always gusto.humanloop.com) if they're explicitly logging
    // out I think its better to fully reset. I'm open to changing this later, or making these
    // cookies longer lasting, especially if we have a good way to direct the users to
    // the others domains.
    Cookies.remove(HUMANLOOP_APP_DOMAIN_COOKIE_NAME, {
      domain: COOKIE_DOMAIN_AVAILABILITY,
    });
    Cookies.remove(HUMANLOOP_API_URI_COOKIE_NAME, {
      domain: COOKIE_DOMAIN_AVAILABILITY,
    });
    mutate();
    setAnalyticsContext(null);
    clearActiveOrganizationSessionStorage();
    analytics.enableTracking();
    // Best to trigger a full reload so data is refetched.
    window.location.href = redirect;
  };

  // TODO: This is not secure!! We should be checking for feature flag access on the backend.
  // TODO: Unify with getFeatureFlag in organization.ts
  const hasAccess = (feature: ProductFeatureFlags): boolean | undefined => {
    // TODO: in future I think the feature flags should be stored in the user object
    // This checks the user object for the feature flag and then the subscription
    if (user) {
      const feature_flags = user.feature_flags || {};
      if (feature in feature_flags) {
        return feature_flags[feature];
      }

      const userActiveOrganization = getActiveOrganizationMembership(user);

      return (
        userActiveOrganization?.organization.active_subscription?.product.feature_flags?.[feature] ||
        userActiveOrganization?.organization.feature_flags?.[feature]
      );
    }
    return undefined;
  };

  // Return the user object and auth methods
  return {
    login,
    access,
    logout,
    forgot,
    reset,
    verifyAndLogin,
    authToken: authStorage ? authStorage.token : null,
    loading,
    user,
    mutate,
    hasAccess,
    setAuthToken,
  };
}

export const getAuthToken = (): string | null => {
  const authStorage = getLocalStorage<AuthStorage | null>(AUTH_STORAGE);
  if (authStorage) {
    if (authStorage.timestamp < Date.now() - AUTH_EXPIRY) {
      return null;
    }
    return authStorage.token;
  }
  return null;
};
